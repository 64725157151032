@import "~@/assets/scss/global.scss";























































































































































































.el-main {
    padding: 3px;
}

.spec-title {
    position: relative;
    height: 38px;
    line-height: 38px;
    padding-left: 5px;
    background: #3a8ee6;
    color: white;

    i {
        position: absolute;
        top: 10px;
        right: 5px;
        cursor: pointer;
    }
}
